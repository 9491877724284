*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.right-4 {
  right: 1rem;
}

.top-4 {
  top: 1rem;
}

.z-20 {
  z-index: 20;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.h-4 {
  height: 1rem;
}

.w-4 {
  width: 1rem;
}

.w-full {
  width: 100%;
}

.appearance-none {
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-6 {
  gap: 1.5rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.bg-\[\#1323ffde\] {
  background-color: #1323ffde;
}

.p-4 {
  padding: 1rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

:root {
  --theme-bg-color: #10121b66;
  --body-font: "Poppins", sans-serif;
}

body {
  font-family: var(--body-font);
  background-color: #010409;
}

.video-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

.video-bg video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.app {
  background-color: var(--theme-bg-color);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  color: #fff;
  z-index: 1;
}

.button {
  color: #c9d1d9;
  z-index: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  appearance: none;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  transition: color 1s;
  display: inline-block;
}

.button:hover {
  color: #fff;
  transition: color 1s;
}

.button:before, .button:after {
  z-index: -1;
  content: "";
  opacity: 1;
  background-image: linear-gradient(#010409, #010409), radial-gradient(circle at 0 0, #9867f0, #ed4e50);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 1px double #0000;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  animation: 1s BorderAnimIn;
  display: inline-block;
  position: absolute;
  top: -1px;
  left: -1px;
}

@keyframes BorderAnimIn {
  0% {
    background-image: none;
    border: 1px solid #fff;
  }

  100% {
    background-image: linear-gradient(#010409, #010409), radial-gradient(circle at 0 0, #9867f0, #ed4e50);
    border: 1px solid #0000;
  }
}

@keyframes BorderAnimHover {
  0% {
    background-image: linear-gradient(#010409, #010409), radial-gradient(circle at 0 0, #9867f0, #ed4e50);
    border: 1px solid #0000;
  }

  100% {
    background-image: none;
    border: 1px solid #fff;
  }
}

.button:hover:before, .button:hover:after {
  border: 1px solid #fff;
  animation: 1s BorderAnimHover;
}

.Alert {
  color: #fff;
  background-color: #4a5568;
  background-image: linear-gradient(315deg, #4a5568 0%, #2c3e50 74%);
  border: 1px solid #ffffff60;
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 12px;
}

footer {
  color: #ffffffde;
  text-align: center;
  z-index: 111;
  padding-bottom: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

footer .fly {
  transform-origin: 50%;
  display: inline-block;
  transform: rotate(-45deg);
}

footer:hover .fly {
  animation-name: fly-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes fly-animation {
  0% {
    transform: translateX(0)rotate(-45deg);
  }

  25% {
    transform: translateX(-3px)rotate(-45deg);
  }

  50% {
    transform: translateX(0)rotate(-45deg);
  }

  75% {
    transform: translateX(3px)rotate(-45deg);
  }

  100% {
    transform: translateX(0)rotate(-45deg);
  }
}

input[type="range"] {
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ff0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.hover\:border-slate-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

@media (width >= 768px) {
  .md\:p-6 {
    padding: 1.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.\[\&\:\:-webkit-slider-thumb\]\:h-5::-webkit-slider-thumb {
  height: 1.25rem;
}

.\[\&\:\:-webkit-slider-thumb\]\:w-5::-webkit-slider-thumb {
  width: 1.25rem;
}

.\[\&\:\:-webkit-slider-thumb\]\:appearance-none::-webkit-slider-thumb {
  appearance: none;
}

.\[\&\:\:-webkit-slider-thumb\]\:rounded-full::-webkit-slider-thumb {
  border-radius: 9999px;
}

.\[\&\:\:-webkit-slider-thumb\]\:bg-\[white\]::-webkit-slider-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

/*# sourceMappingURL=index.9be28db0.css.map */
