@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-bg-color: rgba(16 18 27 / 40%);
  --body-font: "Poppins", sans-serif;
}

body {
  background-color: #010409;
  font-family: var(--body-font);
}

.video-bg {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.video-bg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.app {
  background-color: var(--theme-bg-color);
  backdrop-filter: blur(20px);
  color: white;
  z-index: 1;
}

.button {
  color: #c9d1d9;
  z-index: 1;
  display: inline-block;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border-radius: 5px;
  appearance: none;
  transition: color 1s;
}

.button:hover {
  color: #fff;
  transition: color 1s;
}

.button::before,
.button::after {
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: -1;
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(#010409, #010409), radial-gradient(circle at top left, #9867f0, #ed4e50);
  background-clip: content-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  border-radius: 5px;
  opacity: 1;
  animation: BorderAnimIn 1s;
}

@keyframes BorderAnimIn {
  0% {
    border: 1px solid white;
    background-image: none;
  }
  100% {
    border: 1px solid transparent;
    background-image: linear-gradient(#010409, #010409), radial-gradient(circle at top left, #9867f0, #ed4e50);
  }
}

@keyframes BorderAnimHover {
  0% {
    border: 1px solid transparent;
    background-image: linear-gradient(#010409, #010409), radial-gradient(circle at top left, #9867f0, #ed4e50);
  }
  100% {
    border: 1px solid white;
    background-image: none;
  }
}

.button:hover::before,
.button:hover::after {
  border: 1px solid white;
  animation: BorderAnimHover 1s;
}

.Alert {
  background-color: #4a5568;
  background-image: linear-gradient(315deg, #4a5568 0%, #2c3e50 74%);
  color: #fff;
  border: 1px solid #ffffff60;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 12px;
}

footer {
  color: #ffffffde;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  padding-bottom: 1em;
  z-index: 111;
}

footer .fly {
  transform-origin: 50% 50%;
  display: inline-block;
  transform: rotate(-45deg);
}

footer:hover .fly {
  animation-name: fly-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes fly-animation {
  0% {
    transform: translateX(0px) rotate(-45deg);
  }
  25% {
    transform: translateX(-3px) rotate(-45deg);
  }
  50% {
    transform: translateX(0px) rotate(-45deg);
  }
  75% {
    transform: translateX(+3px) rotate(-45deg);
  }
  100% {
    transform: translateX(0px) rotate(-45deg);
  }
}

input[type="range"] {
  cursor: pointer;
}


input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: yellow;
}